import { Column as GridColumn, Row } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { dateColumnsName } from './constants';
import { formatDateTime } from 'utils/helpers';

export const customizeCell = (cell: ExcelCell, row: Row, column: GridColumn) => {
  customizeInvoiceExtractDateColumns(cell, row, column, dateColumnsName)
};

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnsName.forEach((columnName) => {
      if (row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]);
      }
    });
    return formattedRow;
  });
};

// Date formatting for Excel download
function customizeInvoiceExtractDateColumns(
  cell: ExcelCell,
  row: Row,
  column: GridColumn,
  dateColumnName: string[]
) {
  
  if (dateColumnName?.includes(column.name)) {
    if (row[column.name] !== '') {
      cell.value = new Date(row[column.name]);    
      cell.value = cell.value.toString().split(/\+|-/)[0]; 
      cell.value = (new Date(cell.value)).toISOString().split('T')[0];
      cell.value = (new Date(cell.value));
      cell.numFmt = 'dd/mm/yy h:mm:ss';
    }
  }
}
